/* Estilos globales para este componente */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
  }
  
  .title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    padding-bottom: 20px;
  }
  
  .subtitle {
    font-size: 2rem;
    color: #555;
    margin-top: 40px;
  }
  
  .intro p,
  .section p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.6;
  }
  
  /* Estilos para diferentes pantallas */
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .subtitle {
      font-size: 1.5rem;
    }
  
    .intro p,
    .section p {
      font-size: 1.1rem;
    }
  }
  