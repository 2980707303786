/* Aseguramos que la página de mensajes ocupe siempre el 100% de la pantalla */
.message-page {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ocupa siempre el 100% del viewport */
    max-height: 100vh; /* Aseguramos que no crezca más de la pantalla */
    overflow: hidden; /* Para que el contenido exterior no sea desplazable */
  }
  
  /* La sección de mensajes será la que se desplace si hay muchos mensajes */
  .messages-container {
    flex: 1; /* Ocupa todo el espacio disponible entre el header y el input */
    overflow-y: auto; /* Permitir que los mensajes se desplacen si exceden el espacio */
    padding: 16px;
    background-color: rgba(242, 242, 242, 0.8);
  }
  
  /* Mantener el input fijo en la parte inferior */
  .message-input-section {
    border-top: 1px solid #ccc;
    padding: 12px;
    background-color: white;
    position: relative;
  }
  