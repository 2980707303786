.sidebar {
  background-color: #f3f4f6;
  padding: 20px;
  width: 100%;
  max-width: 250px;
}

.sidebar-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.sidebar-item {
  margin: 10px 0;
  cursor: pointer;
}

.logout-button {
  background-color: #e74c3c;
  color: white;
  padding: 10px;
  border: none;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard-main {
  flex-grow: 1;
  padding: 20px;
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.dashboard-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
  }

  .dashboard-main {
    padding: 15px;
    width: 100%;
  }
}
