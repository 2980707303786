/* Estilos generales para el header */
.header-inicio {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #ffffff; /* Fondo blanco */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
  z-index: 100;
}

/* Contenedor del header */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px; /* Ancho máximo */
  margin: 0 auto;
  padding: 1rem 2rem; /* Espaciado interno */
}

/* Logo */
.header-logo {
  width: 150px; /* Tamaño del logo */
  height: auto;
  display: flex;
  align-items: center; /* Alineación vertical del logo */
}

/* Navegación */
.nav-links {
  display: flex;
  gap: 2rem; /* Espacio entre los enlaces */
  align-items: center; /* Asegura que los enlaces estén alineados verticalmente */
}

/* Estilos para la navegación y el menú desplegable */
.nav-item-dropdown {
  position: relative; /* Necesario para posicionar el menú desplegable */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 100;
}

.dropdown-item {
  font-size: 1rem;
  color: #000000;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #e0e0e0; /* Fondo gris al pasar el mouse */
}

/* Estilos para los enlaces normales */
.nav-item {
  font-size: 1rem; /* Tamaño de fuente */
  font-weight: 500; /* Peso de fuente */
  color: #000000; /* Texto negro */
  text-decoration: none;
  transition: color 0.3s ease, box-shadow 0.3s ease; /* Transición suave para el color y sombra */
  padding: 0.5rem 1rem;
  border-radius: 25px; /* Bordes redondeados para el efecto */
}

.nav-item:hover {
  color: #555555; /* Color gris al pasar el mouse */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Efecto de sombra al pasar el mouse */
}

/* Botones de login y registro */
.auth-buttons {
  display: flex;
  align-items: center; /* Alinea verticalmente los botones */
  gap: 1rem; /* Espacio entre los botones */
}

.signup-button {
  background-color: transparent;
  border: 1px solid #000000; /* Borde negro */
  color: #000000;
  padding: 0.5rem 1.5rem;
  border-radius: 25px; /* Bordes redondeados */
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Transición suave para sombra y fondo */
}

.signup-button:hover {
  background-color: #8DDC61; /* Fondo negro al hacer hover */
  color: #ffffff; /* Texto blanco al hacer hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra al pasar el mouse */
  font-weight:700;
}

/* Estilos para el menú hamburguesa */
.hamburger-btn {
  display: none;
  cursor: pointer;
}

.hamburger-btn:hover {
  opacity: 0.8;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  /* Ocultar menú de escritorio en pantallas pequeñas */
  .desktop-menu {
    display: none;
  }

  /* Mostrar el menú hamburguesa en pantallas pequeñas */
  .hamburger-btn {
    display: block; /* Mostrar el botón de hamburguesa */
    cursor: pointer;
  }

  /* Mostrar el menú móvil en pantallas pequeñas */
  .mobile-menu {
    display: block; /* Hacer visible el menú móvil */
  }
}

@media (min-width: 769px) {
  /* Mostrar el menú de escritorio en pantallas grandes */
  .desktop-menu {
    display: flex;
  }

  /* Ocultar el menú móvil y el botón hamburguesa en pantallas grandes */
  .mobile-menu,
  .hamburger-btn {
    display: none;
  }
}


/* Estilos para el menú móvil */
.mobile-menu {
  display: none; /* Oculto por defecto en pantallas grandes */
}

@media (max-width: 768px) {
  /* Mostramos el menú móvil en pantallas pequeñas */
  .mobile-menu {
    display: block;
  }

  /* Ocultar el menú de pantallas grandes */
  .desktop-menu {
    display: none;
  }

  .nav-links-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff; /* Fondo blanco */
    color: #000000; /* Color negro */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;
    z-index: 200;
    transition: transform 0.3s ease-in-out;
  }

/* Ajuste general para los botones con flecha */
.nav-item-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  padding: 1rem 0;
  font-size: 1.5rem;
  font-weight: 500;
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transition: color 0.3s ease;
}

/* Ajustes específicos para las flechas */
.nav-item-mobile .icon {
  margin-left: auto; /* Alinea la flecha automáticamente al final */
}

/* Para los submenús de atenciones, recursos, apoyo */
.nav-item-dropdown-mobile {
  width: 100%; /* Para asegurar que ocupe el 100% del ancho del contenedor */
}

/* Hover en botones */
.nav-item-mobile:hover {
  color: #555555;
}

/* Estilos para los dropdown */
.dropdown-menu-mobile {
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


  .dropdown-item-mobile {
    font-size: 1.2rem;
    color: #000000; /* Color negro */
    text-decoration: none;
    padding: 0.5rem 0;
    transition: color 0.3s ease;
  }

  .dropdown-item-mobile:hover {
    color: #555555;
  }

  .signup-button-mobile {
    margin-top: 2rem;
    background-color: #ffffff; /* Fondo blanco */
    color: #000000; /* Texto negro */
    padding: 1rem 2rem;
    border: 2px solid #000000;
    border-radius: 25px;
    font-weight: 500;
    text-align: center;
    width: auto; /* Cambiado a auto para que no ocupe todo el ancho */
    display: block;
    margin-left: auto; /* Alineación horizontal centrada */
    margin-right: auto; /* Alineación horizontal centrada */
}


  .signup-button-mobile:hover {
    background-color: #8DDC61; /* Fondo gris claro al hacer hover */
    color: #ffffff; /* Mantén el texto negro */
    font-weight:700;
  }

  .hamburger-btn {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 201;
  }
}

