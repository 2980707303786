/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Estilos generales */

/* Títulos y subtítulos */
.text-title {
  font-size: 3.75rem; /* 6xl en Tailwind */
  line-height: 1;
}

.text-subtitle {
  font-size: 1.5rem; /* 2xl en Tailwind */
  line-height: 2rem;
  color: #4b5563; /* text-gray-600 */
}

/* Botones globales */
.btn-primary {
  background-color: #4ade80; /* bg-green-400 */
  padding: 0.75rem 1.5rem; /* py-3 px-6 */
  font-size: 0.875rem; /* text-sm */
  font-weight: 600; /* font-semibold */
  color: #ffffff; /* text-white */
  border-radius: 0.375rem; /* rounded-md */
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #38a169; /* bg-green-500 */
}

.btn-secondary {
  background-color: transparent;
  color: rgb(99, 99, 99); /* text-gray-700 */
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.btn-secondary:hover {
  color: #74b2ec; /* hover:text-blue-400 */
  background-color: rgba(48, 141, 40, 0.2); /* hover:bg-green-200 */
}

/* Grid Layouts */
.grid-2-col {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)); /* grid-cols-2 */
  gap: 2rem; /* gap-8 */
}

.grid-3-col {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); /* grid-cols-3 */
  gap: 2rem; /* gap-8 */
}

/* Sección de estadísticas */
.stat-card {
  background-color: #f9fafb; /* bg-gray-50 */
  border-radius: 1rem; /* rounded-2xl */
  padding: 2rem; /* p-8 */
  text-align: center;
}

/* Ajustes globales para margenes y padding */
.container {
  max-width: 80rem; /* max-w-7xl */
  margin: 0 auto;
}

