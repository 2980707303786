.psicologo-crud {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: auto;
  width: 100%; /* Ajuste adicional */
}

.psicologo-crud-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: #d1d5db;
}

.error-message {
  color: #e74c3c;
  margin-top: 10px;
}

.success-message {
  color: #2ecc71;
  margin-top: 10px;
}

.psychologist-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.psychologist-table th,
.psychologist-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
}

.delete-button {
  background-color: #e74c3c;
  color: white;
  padding: 8px;
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .psicologo-crud {
    width: 90%;
    padding: 10px;
  }

  .dashboard-main,
  .psychologist-table th,
  .psychologist-table td {
    padding: 8px;
    font-size: 0.875rem;
  }

  button {
    padding: 8px;
    font-size: 0.875rem;
  }

  .delete-button {
    padding: 5px;
    font-size: 0.75rem;
  }
}
